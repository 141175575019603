import { View } from "@/ui";
import { Colors } from "@/constants";
import Header from "@/ui/Header";
import LandingPageHeading from "@/ui/LandingPageHeading";
import LandingFeatures from "@/ui/LandingFeatures";
import LandingPricing from "@/ui/LandingPricing";
import LandingPageFaq from "@/ui/LandingPageFaq";
import LandingPageReviews from "@/ui/LandingPageReviews";
import LandingPageFooter from "@/ui/LandingPageFooter";
import LandingPageAction from "@/ui/LandingPageAction";
import { useAuth } from "@/models/auth";

function Home() {
  const auth = useAuth();

  return (
    <View style={{ flex: 1, backgroundColor: Colors.white, zIndex: 1 }}>
      <View style={{ zIndex: 10, backgroundColor: Colors.heading }}>
        <Header />
        <LandingPageHeading login={auth.googleSignIn} />
      </View>
      <LandingFeatures />
      <View id="pricing">
        <LandingPricing login={auth.googleSignIn} />
      </View>
      <LandingPageReviews />
      <LandingPageFaq />
      <LandingPageAction login={auth.googleSignIn} />
      <LandingPageFooter />
    </View>
  );
}

export default Home;
